<template>
  <div>
    <HeaderPage></HeaderPage>
    <div class="box">
      <div data-v-035ab6c4="" class="ng-row">
                        <div data-v-035ab6c4="" class="company-desc">
                            
                            <p data-v-035ab6c4="" style="width: 350px; margin: 0 auto 30px; font-size: 30px;">Acceptance of Terms</p>
                            <p data-v-035ab6c4="" class="desc">
                                These terms and conditions of use ("Terms of Use") constitute a legal agreement that
                                governs your relationship with Impressive Innovation software services and its
                                affiliated companies (collectively referred to as "NG" or "We" or “us”) regarding your
                                use of NG's social games and related services, which include applications for mobile
                                devices and NG-branded Web sites (the “Services"). These Terms of Use apply to any
                                Services provided by or Site operated by NG, including, without limitation, the NG
                                casino located at https://dev.nasigames.com or any other sites on which these Terms of Use are
                                posted, and/or where any NG application, Services, or product is licensed, downloaded or
                                otherwise accessed through third party sites or sources (in each case, the “Site”).
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                By registering with, using or otherwise accessing the Services, including browsing the
                                Site, accessing the Services with a mobile device or installing software obtained from
                                the Site, you acknowledge that you have read, understood, accepted and agreed to be
                                bound by these Terms of Use and any other terms and conditions referenced and
                                incorporated herein, including, without limitation, the Privacy Policy referenced herein
                                ("Privacy Policy"). If you do not accept these Terms of Use and the Privacy Policy, you
                                are not authorized to use the Services.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                We may modify these Terms of Use and the Privacy Policy at any time and such
                                modification will be effective upon the date the modified Terms of Use and Privacy
                                Policy are posted to the Site. You are responsible for reviewing these Terms of Use and
                                Privacy Policy for modifications prior to accessing, using or downloading the Services.
                                By your continued use of the Services, you acknowledge and agree that you shall be bound
                                by any such modifications. If you do not wish to be bound by the modified Terms of Use
                                and Privacy Policy, you must cease use of the Services.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Privacy</p>
                            <p data-v-035ab6c4="" class="desc">
                                A link to the NG Privacy Policy is provided here. The Privacy Policy governs any
                                personal information you provide to us. By using the Services or accessing the Site you
                                agree to the terms of the Privacy Policy.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Binding Arbitration and Class
                                Waiver</p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Right to Use and Limitations
                                on Use</p>
                            <p data-v-035ab6c4="" class="desc">
                                Subject to your agreement and continued compliance with the Terms of Use and Privacy
                                Policy, NG grants you a non-exclusive, non-transferable, revocable limited right to
                                access and use the Services through a supported Web browser or mobile device solely for
                                your own non-commercial, entertainment purposes.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You represent and warrant that you have full right and authority to use the Services and
                                to be bound by these Terms of Use. You further agree that your use of the Services shall
                                be lawful and that you will comply with the usage rules. In furtherance of the
                                foregoing, and as an example and not as a limitation, you agree that you will not:
                                <br data-v-035ab6c4="">
                                1. Modify, adapt, translate, reverse engineer, or disassemble any portion of the
                                Services;
                                <br data-v-035ab6c4="">
                                2. Restrict or inhibit any other person from use of the Services or interfere with or
                                disrupt the operation of the Services or the servers or networks used to make the
                                Services available or violate any requirements, procedures, policies, or regulations of
                                such networks;
                                <br data-v-035ab6c4="">
                                3. Create false personas, multiple identities, multiple user accounts, set up an account
                                on behalf of someone other than yourself, use bots or other automated software programs
                                to defraud NG or any third party or take any other action which otherwise violates these
                                Terms of Use and/or the terms of service of any third-party applications or social
                                networks through which the Services are accessed;
                                <br data-v-035ab6c4="">
                                4. Attempt to obtain passwords or other private information from other users of the
                                Services including personally identifiable information;
                                <br data-v-035ab6c4="">
                                5. Use the Services if you have previously been removed or banned by NG;
                                <br data-v-035ab6c4="">
                                6. Develop, distribute or use any software programs or other applications that allow the
                                user to cheat or obtain an unintended advantage while using the Services;
                                <br data-v-035ab6c4="">
                                7. Exploit or distribute any game error or technical glitch that provides an unintended
                                advantage to a user, including but not limited to accessing one time promotions more
                                than once;
                                <br data-v-035ab6c4="">
                                8. Transfer, sell, or re-sell Virtual Currency or Virtual Goods (as defined below), to
                                other individuals, parties, or entities, or fraudulently acquire Virtual Currency or
                                Virtual Goods;
                                <br data-v-035ab6c4="">
                                9. Rent, lease, sell, trade, or otherwise transfer or share your account to or with
                                anyone without NG's written permission;
                                <br data-v-035ab6c4="">
                                10. Access or use an account which has been rented, leased, sold, traded, or otherwise
                                transferred from the account creator without NG's written permission;
                                <br data-v-035ab6c4="">
                                11. Use the Services to post or transmit any virus, worm, Trojan Horse, easter egg, time
                                bomb, spyware, or other computer code, file, or program that is harmful or invasive or
                                may or is intended to damage or hijack the operation of, or to monitor the use of, any
                                hardware, software, or equipment; and
                                <br data-v-035ab6c4="">
                                12. Attempt to use the Services on or through any website or service that is not
                                authorized by NG
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Age</p>
                            <p data-v-035ab6c4="" class="desc">The Services are intended for those 18 years of age.
                                Without diminishing the foregoing, to create an account, access or participate in the
                                Services, you must be a natural person, at least 13 years old, and if you are under the
                                age of 18 you declare that you have the consent of a legal guardian who has reviewed and
                                agreed to these Terms of Use. Failure to comply with this condition will result in the
                                closing of your account and the loss of all Virtual Currency or Virtual Goods acquired
                                through your use of the Services to the extent legally permissible.</p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Access; User Names and
                                Passwords</p>
                            <p data-v-035ab6c4="" class="desc">
                                You may log in to use the Services by using your Facebook account. By using your
                                Facebook account to log in to the Services, you affirmatively consent to our sharing of
                                your actions and data with Facebook. If you are using the Services via Facebook, you
                                must have a valid, active Facebook account to use and access the Services.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                If you are accessing the Services from a mobile device, you will also need to have an
                                account with a source of downloadable mobile applications, such as an iTunes or Google
                                Play account. You are solely responsible for any such account, and with your compliance
                                with any agreement you may enter with respect to such account. You may need to update
                                third-party software, such as your operating system, from time to time to access the
                                Services. We are not responsible in any way for such third-party software. You are
                                responsible for the device you use to access the Services and the fees to connect to the
                                Internet and application marketplaces or for data or cellular usage to download and use
                                the Services.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You are responsible for maintaining the confidentiality of any password you may use to
                                access the Services, and agree not to transfer your password or user login information
                                ("User Name"), or lend or otherwise transfer your use of or access to the Services, to
                                any third party. You are fully responsible for all interaction with the Services that
                                occurs in connection with your password or User Name. You agree to immediately notify us
                                of any unauthorized use of your password or User Name or any other breach of security
                                related to your account or the Services, and to ensure that you "log off" or exit from
                                your account with the Services (if applicable) at the end of each session. We are not
                                liable for any loss or damage arising from your failure to comply with any of the
                                foregoing obligations, and you agree to indemnify and hold NG harmless for any improper
                                or illegal use of any of your NG account(s). This includes illegal or improper use by
                                someone to whom you have given permission to use your account(s) or whom you have
                                negligently allowed to access your account(s).
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You further represent and warrant that all information you supply to NG is complete and
                                accurate. Knowingly submitting incomplete or inaccurate information may result in
                                immediate termination of your participation and forfeiture of any Virtual Currency and
                                Virtual Goods to which you are otherwise entitled, at NG's sole discretion, to the
                                extent legally permissible.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Subscriptions</p>
                            <p data-v-035ab6c4="" class="desc">
                                Some of NG’s Sites may offer the ability to purchase subscription services. If you
                                purchase a subscription, then by clicking the purchase button you are requesting that NG
                                begin supplying the subscription services immediately and are entering into a monthly
                                subscription contract with us. You are also authorizing a charge to you of a monthly
                                subscription fee at the rate quoted at the time of purchase. For subscription services
                                purchased using Services or Sites played on a platform such as Facebook, Apple or
                                Google, the platform will charge you for the subscription fee and the platform’s payment
                                terms will apply. Please review the appropriate platform’s payment terms for additional
                                information.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                The charges will be applied to the payment instrument you provide when you start your
                                subscription (or to a different payment instrument, if you change your account
                                information). Please note that prices and charges are subject to change, and in such
                                instance, we will let you know in advance.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                Your subscription will automatically renew each month unless and until you terminate
                                your subscription or we terminate it. You must cancel your subscription before it renews
                                each month, otherwise payment of the next month's subscription fees will be taken
                                automatically via your chosen payment method.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You may cancel at any time directly through the platform for games played on a platform.
                                For each platform, please review their customer support websites or contact their
                                customer support numbers for information regarding canceling auto-renewing
                                subscriptions.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                SUBSCRIPTION PAYMENTS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS OR CREDITS FOR
                                PARTIALLY USED SUBSCRIPTION PERIODS, EXCEPT FOR EU CUSTOMERS THAT CANCEL WITHIN 14 DAYS
                                OF THEIR INITIAL PURCHASE, AS EXPLAINED BELOW.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                If you reside in the European Union, subscription services may be cancelled within 14
                                days from the date of your purchase and you may obtain a refund of your initial payment
                                minus a reasonable pro-rated portion to cover your use of the subscription service prior
                                to cancellation. Please refer to the relevant platform’s payment terms relevant to
                                subscriptions for more information on exercising this cancellation right.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                NG may occasionally offer a one-time or limited-time bonus to players that have a
                                current subscription or who agree to start a subscription. You understand that these
                                items will not be a part of your ongoing subscription.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Virtual Currency</p>
                            <p data-v-035ab6c4="" class="desc">
                                The Services may include a virtual, in-game currency ("Virtual Currency") including, but
                                not limited to chips, coins, cash, credits, or points, that may be purchased through the
                                Services for "real world" money if you are legally permitted to purchase such Virtual
                                Currency in your country, province, jurisdiction and/or state of residence. The Services
                                may also include virtual, in-game digital items ("Virtual Goods") that may be purchased
                                through the Services for "real world" money (if you are legally permitted to purchase
                                such Virtual Goods in your country, province, jurisdiction and/or state of residence) or
                                for Virtual Currency. Regardless of the terminology used, Virtual Currency and Virtual
                                Goods may never be redeemed for "real world" money, goods or other items of monetary
                                value from NG or any other party. Prices and availability of Virtual Currency and/or
                                Virtual Goods are subject to change without notice. Virtual Currency and/or Virtual
                                Goods may only be purchased or acquired from us and through means we provide on the
                                applicable website or otherwise expressly authorize. We reserve the right to refuse your
                                request to purchase and/or acquire Virtual Currency and/or Virtual Goods for any reason.
                                Virtual Currency and Virtual Goods may only be held by legal residents of jurisdictions
                                where access to and use of the Services are permitted. Virtual Currency and Virtual
                                Goods may only be purchased or acquired through means specifically authorized by NG.
                                When you purchase Virtual Currency, it will reside in your NG account until discharged
                                through use of the Services or otherwise surrendered as a result of termination of the
                                Services in accordance with these Terms of Use. In certain circumstances and to
                                facilitate offline play, your Virtual Currency may be stored on your mobile device, and
                                in such instance such Virtual Currency cannot be used from any other source. Further, in
                                certain circumstances Virtual Currency stored on a mobile device may not be transferred
                                to another device, and unless otherwise prohibited by law, NG shall have no obligation
                                to you if such Virtual Currency is not transferred.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                Other than a limited, personal, revocable, non-transferable, non-sublicenseable license
                                to use the Virtual Goods or Virtual Currency with the Services, you have no right or
                                title in or to any such Virtual Goods or Virtual Currency appearing or originating with
                                the Services, or any other attributes associated with use of the Services or stored
                                within the Services. NG has the absolute right to manage, regulate, control, modify
                                and/or eliminate such Virtual Currency and/or Virtual Goods as it sees fit in its sole
                                discretion to the extent legally permissible, and NG shall have no liability to you or
                                anyone for the exercise of such rights. In addition to the foregoing, NG may selectively
                                remove or revoke Virtual Currency and/or Virtual Goods associated with your account.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                The transfer of Virtual Currency and Virtual Goods is strictly prohibited. Any attempt
                                to do so is in violation of these Terms of Use and may result in a lifetime ban from the
                                Services and possible legal action.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You understand and agree that all sales of Virtual Currency and Virtual Goods are final
                                and that NG is not required to provide a refund for any reason. All Virtual Currency and
                                Virtual Goods are forfeited if your account is terminated or suspended for any reason,
                                in NG's sole and absolute discretion, or if the Services are no longer available. To the
                                extent legally permissible, if your account, or a particular subscription for the
                                Service associated with your account, is terminated, suspended and/or if any Virtual
                                Currency and/or Virtual Goods are selectively removed or revoked from your account, no
                                refund will be granted, no Virtual Currency and/or Virtual Goods will be credited to you
                                or converted to cash or other forms of reimbursement.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Intellectual Property
                                Ownership</p>
                            <p data-v-035ab6c4="" class="desc">
                                NG and its licensors retain all rights in the content within the Services (including,
                                but not limited to, applications, software, designs, graphics, texts, information,
                                pictures, video, sound, music, and other files, and their selection and arrangement)
                                (collectively, the "Content"). The Content is protected by applicable copyright, trade
                                dress, patent, and trademark laws, international conventions, and other laws protecting
                                intellectual property and related proprietary rights. You may only use the Content in
                                connection with your use of the Services for personal, noncommercial, entertainment
                                purposes. The Content may not be used by you in any other manner, or for any other
                                purpose, without our express written permission and/or the consent of any third party we
                                deem necessary, except as provided for herein. You hereby acknowledge that you do not
                                acquire any ownership rights by using the Services or by accessing any of the Content.
                                Any unauthorized use by you of the Content may violate copyright laws, trademark laws,
                                the laws of privacy and publicity, and other applicable regulations and statutes.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                Further, by accessing or using the Services, you acknowledge and agree that any name,
                                logo, trademark, brand, or service mark ("Trademarks") used with the Services is owned
                                or licensed by us and may not be used by you without our prior written approval. Nothing
                                contained in the Services should be construed as granting by implication, estoppel, or
                                otherwise, any license or right to use any such Trademarks without our written consent
                                and/or consent of such third party that owns the Trademarks.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                Any communications or materials you transmit to NG by electronic mail or otherwise,
                                including any data, questions, comments, suggestions, or the like, are and will be
                                treated as, non-confidential and non-proprietary. NG is free to use, reproduce, modify,
                                disclose, transmit or post any ideas, concepts, know-how or techniques contained in any
                                communication you send to NG for any purpose whatsoever including, but not limited to,
                                developing, manufacturing and marketing products using such information, without any
                                compensation to you or acknowledgment that you were the source of such materials.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">User Information and Content
                            </p>
                            <p data-v-035ab6c4="" class="desc">
                                By using the Services or participating on the Site, you agree that the Site (a) may
                                display your first name, first initial of your last name, picture, profile and game
                                records on the Site, and (b) may print, publish, broadcast and use, worldwide, in any
                                media and at any time, your name, picture, voice, likeness, and/or biographical
                                information for promotional purposes without compensation.. You agree that you are
                                solely responsible for any content, location information, messages, posts, comments,
                                data, text, images, photographs, videos or other materials that you transmit through the
                                Services ("User Content"). By submitting, transmitting, posting, uploading, or otherwise
                                providing any User Content in connection with the Services, you are granting NG a
                                royalty-free, fully paid, non-exclusive, sublicensable, transferable, irrevocable,
                                perpetual, unrestricted, worldwide license to use, publish, transmit, perform, display,
                                store, distribute, reproduce, modify, create derivative works from, and otherwise use
                                any and all user Content for any purpose, including, without limitation, advertising and
                                promotional purposes. No credit, approval or compensation is due to you for any such use
                                of the User Content you may submit.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                NG reserves the right (but at no time be obligated to), in its sole discretion, remove,
                                block, edit move, disable or permanently delete User Content with or without notice for
                                any reason whatsoever. You hereby agree that, to the maximum extent permitted by
                                applicable law, NG shall at no time be liable for the removal, modification, blocking,
                                moving or deletion of User Content.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You further represent and warrant that you own or otherwise control any and all rights
                                in and to the User Content and that public posting of the User Content by NG will not
                                infringe or violate the rights of any third party in any manner. You also agree that the
                                User Content shall not include any personal identification, such as personal names,
                                email addresses, or other indicia identifying any other person, including, without
                                limitation, celebrities and/or other public or private figures, living or dead, or that
                                is invasive of a person's privacy. Further, the User Content shall not include any of
                                the following:
                                <br data-v-035ab6c4="">
                                1. comments or other materials that are sexually oriented, explicit or suggestive or
                                exploit people in a sexual or violent manner;
                                <br data-v-035ab6c4="">
                                2. comments or other materials that are violent or derogatory of any ethnic, racial,
                                gender or religious group;
                                <br data-v-035ab6c4="">
                                3. comments or other materials that harass or advocate the harassment of another person;
                                <br data-v-035ab6c4="">
                                4. comments or other materials that promote the illegal use of alcohol, drugs, or
                                tobacco, firearms/weapons or promotes any activities that may be construed as illegal;
                                <br data-v-035ab6c4="">
                                5. comments or other materials that are false or misleading or promote illegal
                                activities or conduct that is abusive, threatening, obscene, defamatory or libelous; or
                                <br data-v-035ab6c4="">
                                6. comments or other materials that infringe any party's trademark, trade secret,
                                copyright or other proprietary rights.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You acknowledge that we are not responsible for any User Content posted in connection
                                any portion of the Services. We are merely providing access to the Services and User
                                Content as a service to our users to be used in accordance with these Terms of Use. With
                                respect to any User Content posted by other users, such users are solely responsible for
                                the User Content they post, including, without limitation, the reliability, accuracy,
                                and truthfulness of any such User Content. Similarly, we have no control over whether
                                such User Content is of a nature that other users might find offensive, distasteful, or
                                otherwise unacceptable and, accordingly, we expressly disclaim any responsibility for
                                any User Content. Just as when you view content in any other setting, you should
                                exercise appropriate discretion, good judgment, and caution in accessing User Content in
                                the Services and in taking any actions based upon such User Content. Accordingly, you
                                will bear all risks associated with any such User Content that you access or use.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                We reserve the right, but not the obligation, to monitor and delete any User Content
                                deemed inconsistent with our policies in our discretion. We also reserve the right to
                                terminate the account of any user who violates any of these Terms of Use, including
                                infringement by a user of third-party copyrighted materials, as determined in our sole
                                discretion.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                If you are aware of any User Content posted in connection with the Services that
                                violates these Terms of Use, please contact us at nasreenkousarmaa@gmail.com. Please provide
                                as much detail as possible, including a copy of the underlying material, the location
                                where we may find it, and the reason such User Content should be removed. Please note
                                that filing a complaint will not guarantee its removal. We only will remove User Content
                                if we believe the measure is necessary, in our sole discretion. To the extent any notice
                                is based on an alleged copyright violation, please follow the instructions set forth in
                                the section entitled "Copyright Infringement Claims." Although we may attempt to monitor
                                User Content, in no event do we assume any particular obligation to do so or liability
                                for failing to either monitor the Site or remove specific User Content.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Communication Channels</p>
                            <p data-v-035ab6c4="" class="desc">
                                The Services may provide communication channels such as forums, communities, or chat
                                areas ("Communication Channels") designed to enable you to communicate with other users
                                of the Services. NG is under no obligation to monitor these communication channels but
                                may do so, and reserves the right to review materials posted to the Communication
                                Channels and to remove any materials, at any time, with or without notice for any
                                reason, at its sole discretion. NG may also terminate or suspend your access to any
                                Communication Channels at any time, without notice, for any reason. You acknowledge that
                                chats, postings, or materials posted by users on the Communication Channels are neither
                                endorsed nor controlled by NG, and these communications should not be considered
                                reviewed or approved by NG. You will be solely responsible for your activities within
                                the Communication Channels and under no circumstances will NG be liable for any activity
                                within the Communication Channels.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                You agree that all your communications within the Communication Channels are public, and
                                you have no expectation of privacy regarding your use of the Communication Channels. NG
                                is not responsible for information that you choose to share on the Communication
                                Channels, or for the actions of other users.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Copyright Notice</p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Termination</p>
                            <p data-v-035ab6c4="" class="desc">
                                You agree that NG, in our sole discretion, may to the extent legally permissible
                                terminate your access to or use of the Services, at any time and for any reason,
                                including without limitation, if we believe that you have violated or acted
                                inconsistently with the letter or spirit of these Terms of Use. Upon any such
                                termination, your right to use some portions, if not all, of the Services will
                                immediately cease. You agree that any termination of your access to or use of the
                                Services may occur without prior notice, and that we may immediately deactivate or
                                delete your account, User Name, and password (excluding your Facebook account), and all
                                related information and files associated with it, and/or bar any further access to such
                                information or files. You agree that we will not be liable to you or any third party for
                                any termination of your access to the Services or to any such information or files or we
                                will be required to make such information or files available to you after any such
                                termination.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Limitation of Liability</p>
                            <p data-v-035ab6c4="" class="desc">
                                BY ACCESSING, USING OR DOWNLOADING THE SERVICES YOU ACKNOWLEDGE AND AGREE THAT SUCH USE
                                IS AT YOUR OWN RISK AND THAT NONE OF THE PARTIES INVOLVED IN CREATING, PRODUCING, OR
                                DELIVERING THE SERVICES OR ANY OF NG, ITS AFFILIATES, SUBSIDIARIES OR ANY OF THEIR
                                EMPLOYEES, AGENTS OR CONTRACTORS (COLLECTIVELY "RELEASED PARTIES") ARE LIABLE FOR ANY
                                DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, OR ANY OTHER
                                LOSSES, COSTS, OR EXPENSES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, LOST PROFITS,
                                LOSS OF DATA, LEGAL FEES, EXPERT FEES, COST OF PROCURING SUBSTITUTE SERVICES, LOST
                                OPPORTUNITY, OR OTHER DISBURSEMENTS) WHICH MAY ARISE, DIRECTLY OR INDIRECTLY, THROUGH
                                THE ACCESS TO, USE OF, RELIANCE ON ANY MATERIAL OR CONTENT ON THE SERVICES, OR BROWSING
                                OF THE SERVICES OR THROUGH YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO
                                OR AUDIO FROM THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                DAMAGES. TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF
                                NG AND/OR ITS AFFILIATES IS LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID NG IN THE ONE
                                HUNDRED AND EIGHTY (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT
                                ANY SUCH CLAIM. WITHOUT LIMITING THE FOREGOING, RELEASED PARTIES ASSUME NO
                                RESPONSIBILITY, AND WILL NOT BE LIABLE, FOR ANY DAMAGES RELATING TO OR CAUSED BY ANY
                                VIRUSES, BUGS, HUMAN ACTION OR INACTION OF ANY COMPUTER SYSTEM, PHONE LINE, HARDWARE,
                                SOFTWARE OR PROGRAM MALFUNCTIONS, OR ANY OTHER ERRORS, FAILURES OR DELAYS IN COMPUTER
                                TRANSMISSIONS OR NETWORK CONNECTIONS ON ACCOUNT OF YOUR ACCESS TO OR USE OF THE
                                SERVICES. RELEASED PARTIES CANNOT AND DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, OR
                                SECURE ACCESS TO THE SERVICES.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Release of Liability</p>
                            <p data-v-035ab6c4="" class="desc">
                                To the fullest extent permitted by law, you agree to release, discharge, defend,
                                indemnify and hold Released Parties harmless from and against all claims, damages,
                                losses, liability, costs and expenses (including without limitation attorneys’ fees)
                                arising out of (a) your use of, access to, or activities in connection with the Services
                                or (b) any violation of these Terms of Use by you or through your account. This defense
                                and indemnification obligation will survive these Terms of Use and your use of the
                                Services.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Disclaimer of Warranties</p>
                            <p data-v-035ab6c4="" class="desc">
                                THE SERVICES, IN WHOLE AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL CONTENT, AND USER
                                MATERIALS), ARE PROVIDED, TRANSMITTED, DISTRIBUTED, AND MADE AVAILABLE "AS IS" WITHOUT
                                EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
                                TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
                                NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY:
                                (A) THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; (B) THAT DEFECTS OR ERRORS IN
                                THE SERVICES WILL BE CORRECTED; (C) THAT THE SERVICES WILL BE FREE FROM VIRUSES OR OTHER
                                HARMFUL COMPONENTS; (D) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND VALIDITY OF ANY
                                INFORMATION OR MATERIALS IN CONNECTION WITH THE SERVICES; (E) THAT YOUR USE OF THE
                                SERVICES WILL MEET YOUR REQUIREMENTS; OR (F) THAT TRANSMISSIONS OR DATA WILL BE SECURE.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Exceptions</p>
                            <p data-v-035ab6c4="" class="desc">
                                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER, EXCLUSION OR LIMITATION OF CERTAIN
                                WARRANTIES, LIABILITIES AND DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS, EXCLUSIONS AND
                                LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, OUR WARRANTIES AND LIABILITY
                                WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Game Play</p>
                            <p data-v-035ab6c4="" class="desc">
                                The games offered on the Site or through the Services may not replicate the odds of
                                winning or the payouts of game credits awarded of similar games found in casinos. The
                                games may also include certain products which resemble “real world” gaming products, but
                                which have a fixed payout amount that is pre-determined by NG in its sole discretion.
                                The game credits awarded may also be variable over time, and we reserve the right to
                                change them from time to time in our sole discretion. NG may change the award
                                percentages without notification to you.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Hacking or Tampering With the
                                Services</p>
                            <p data-v-035ab6c4="" class="desc">
                                You agree that NG is not responsible for any damage, loss, or injury resulting from
                                hacking, tampering, or other unauthorized access or use of the Services. Any attempt to
                                gain unauthorized access to the Services, interfere with procedures or performance of
                                the Services, or deliberately damage or undermine the Services is subject to civil
                                and/or criminal prosecution and will result in immediate termination of your
                                participation and forfeiture of any prizes to which you are otherwise entitled. Any
                                attempt to access or use any portion of the Services by means of automatic, macro,
                                programmed, or similar methods, or to otherwise commit fraud with regard to the
                                Services, will result in civil and/or criminal prosecution, termination of your
                                participation, and forfeiture of all prizes to which you are otherwise entitled.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Disputes with Others</p>
                            <p data-v-035ab6c4="" class="desc">
                                NG reserves the right, but has no obligation, to monitor and/or manage disputes between
                                you and other users of the Services. If you have a dispute with other users, you release
                                and hereby agree to indemnify NG from claims, demands, and damages (actual and
                                consequential) of every kind and nature, known and unknown, arising out of or in any way
                                connected with such dispute.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Legality</p>
                            <p data-v-035ab6c4="" class="desc">
                                You are subject to all laws of the geography in which you reside and from which you
                                access the Services and are solely responsible for obeying those laws. You agree NG
                                cannot be held liable if laws applicable to you restrict or prohibit your participation
                                in the Services. NG makes no representations or warranties, implicit or explicit, as to
                                your legal right to access or participate in the Services nor shall any person
                                affiliated, or claiming affiliation, with NG have authority to make any such
                                representations or warranties. NG reserves the right to monitor the location from which
                                you access the Services and to block access from any jurisdiction in which participation
                                is illegal or restricted.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Links to Third Party Sites</p>
                            <p data-v-035ab6c4="" class="desc">
                                Although the Services may contain links to third party websites, we are not, directly or
                                indirectly, implying any approval, association, sponsorship, endorsement, or affiliation
                                with the linked site, unless specifically stated therein. By accessing, using or
                                downloading the Services, you acknowledge that we have not reviewed all the websites
                                linked to the Services and are not responsible for the content of any off-site pages or
                                any other site linked to the Services. Your linking to any other off-site pages or other
                                sites is at your own risk.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Updates to the Services</p>
                            <p data-v-035ab6c4="" class="desc">
                                We reserve the right, at any time and from time to time, temporarily or permanently,
                                with or without notice, in whole or in part, to the extent legally permissible: modify
                                or discontinue the Services; modify or remove any of the information contained in the
                                Services; limit the Services' availability to any person, geographic area, or
                                jurisdiction we choose; charge fees in connection with the use of the Services; modify
                                and/or waive any fees charged in connection with the Services; and/or offer
                                opportunities to some or all users of the Services. You agree that we will not be liable
                                to you or to any third party for any modification, suspension or discontinuance of the
                                Services, in whole or in part, or of any content contained in the Services. Your
                                continued use of the Services after such changes will indicate your acceptance of such
                                changes.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Governing Law</p>
                            <p data-v-035ab6c4="" class="desc">
                                These Terms of Use are governed by and construed in accordance with the laws of the
                                State of Delaware USA without regard to its principles of conflicts of law.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Entire Agreement</p>
                            <p data-v-035ab6c4="" class="desc">
                                These Terms of Use, including, without limitation, the Privacy Policy, constitute the
                                entire agreement between you and NG and cannot be modified by you. These Terms of Use
                                cannot be modified on an individual basis by any person affiliated, or claiming
                                affiliation, with NG. Nothing in this subsection will prevent NG from modifying these
                                Terms of Use.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Severability</p>
                            <p data-v-035ab6c4="" class="desc">
                                If any portion of these Terms of Use is deemed void or unenforceable, then that
                                provision shall be deemed severable from these Terms of Use and shall not affect the
                                validity and enforceability of the remaining provisions. For the avoidance of doubt, in
                                the event that any portion of any particular section of these Terms of Use, including,
                                without limitation, the section entitled “Binding Arbitration and Class Waiver”, is
                                deemed to be unenforceable, the balance of such section shall remain enforceable.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Local Law.</p>
                            <p data-v-035ab6c4="" class="desc">
                                These Terms of Use do not purport to supersede the laws of the jurisdiction from which
                                you access the Site to the extent such law is deemed to be applicable to your use of the
                                Services. NG will comply with applicable law in all respects regarding your use of the
                                Services. Without limiting the foregoing, we will respect all consumer protections
                                provided by applicable law.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Headings</p>
                            <p data-v-035ab6c4="" class="desc">
                                The headings titles in these Terms of Use are provided solely for convenience and have
                                no legal or contractual significance.
                            </p>
                            <p data-v-035ab6c4="" class="subtitle"><br data-v-035ab6c4="">Force Majeure</p>
                            <p data-v-035ab6c4="" class="desc">
                                We will not be liable in any amount for failure to perform any obligation under these
                                Terms of Use if such failure is caused by the occurrence of any unforeseen contingency
                                beyond our reasonable control, including, without limitation, Internet outages,
                                communications outages, fire, flood, or war.
                                <br data-v-035ab6c4=""><br data-v-035ab6c4="">
                                Last updated March 2024
                            </p>
                        </div>
                    </div>
    </div>
    <!-- 底部组件 -->
    <FooterPage></FooterPage>
  </div>
</template>

<script>
  import FooterPage from "../../components/footer/index.vue"
  import HeaderPage from "../../components/header/index.vue"
export default {
    name:"CONDITIONS",
    components:{
        FooterPage,
        HeaderPage
    }
}
</script>

<style lang="scss" scoped>
.ng-row{
    .company-desc{
        .title{
            font-weight: bolder;
            font-size: 24px;
            line-height: 30px;
            margin: 30px 0 0;
        }
        .subtitle{
            font-weight: bolder;
            font-size: 18px;
            line-height: 30px;
        }
        .desc{
            font-size: 16px;
            line-height: 30px;
        }
    }
}


.box{
  width: 5.2083rem;
  margin: 0 auto;
  .title{
    font-size: 24px;
  }
  p{
    line-height: 30px;
  }
}
</style>
//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import HomePage from '@/view/home/index'
import GamePage from '@/view/game/index'
import ContactUs from "@/view/contactus/index"
import DataPrivacy from "@/view/dataprivacy/index"
import Conditions from "@/view/conditions/index"

const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',
            component: HomePage, 
        },
        {
            name:'game',
            path: '/game',
            component: GamePage, 
        },
        {
            name:"contactus",
            path:"/contactus",
            component:ContactUs,
        },
        {
            name:"dataprivacy",
            path:"/dataprivacy.html",
            component:DataPrivacy,
        },
        {
            name:"conditions",
            path:"/conditions.html",
            component:Conditions,
        },
        {
            path: '*',
            redirect: '/game',
        }
    ]
  })


export default router

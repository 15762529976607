<template>
    <div>
        <HeaderPage message="/contactus"></HeaderPage>
        <!-- 联系我们表单区域 -->
        <div class="box">
            <div class="box_left">
                <h3 style="">Nasi Games</h3>
                <p></p>
                <p style="">If you are interested in collaborating with us or would like to learn more information,
                    please contact us by email!</p>
            </div>
            <div class="box_right">
                <el-form :model="params" ref="dynamicValidateForm" :rules="rules" label-width="150px"
                    class="demo-dynamic">
                    <el-form-item prop="name" label="Name">
                        <el-input v-model="params.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="title" label="Title">
                        <el-input v-model="params.title"></el-input>
                    </el-form-item>

                    <el-form-item prop="content" label="Content">
                        <el-input v-model="params.content" type="textarea"
                            :autosize="{ minRows: 2, maxRows: 6 }"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="Email">
                        <el-input v-model="params.email"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('dynamicValidateForm')">SEND</el-button>
                        <!-- <el-button @click="resetForm('dynamicValidateForm')">RESET</el-button> -->
                    </el-form-item>
                </el-form>
            </div>

        </div>

        <FooterPage></FooterPage>
    </div>
</template>

<script>
import HeaderPage from "@/components/header/index"
import FooterPage from "@/components/footer/index"
export default {
    name: "ContactUs",
    components: {
        HeaderPage,
        FooterPage,
    },
    data() {
        return {
            params: {},
            rules: {
                name: [{ required: true, message: 'Name cannot be empty!', trigger: 'blur' },],
                title: [{ required: true, message: 'Title cannot be empty!', trigger: 'blur' },],
                content: [{ required: true, message: 'The message content cannot be empty!', trigger: 'blur' },],
                email: [
                    { required: true, message: 'Please enter your email address', trigger: 'blur' },
                    { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                ],
            },
        }
    },
    methods: {
        // 发送邮件
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    window.location.href = "mailto:drewtuttle@163.com?subject=【Guanwang】";
                } else {
                    return false;
                }
            });
        },
        // 重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    width: 6.25rem;
    margin: 0 auto;
    padding-top: 150px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .box_left {
        width: 2.0833rem;

        h3 {
            font-size: 36px;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
        }
    }

    .box_right {
        width: 2.3438rem;
    }
}

@media (max-width: 1000px) {
    .box {
        width: 100%;
        margin: 0 auto;
        padding-top: 150px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .box_left {
            width: 80%;
            margin: 0 auto;
            h3 {
                font-size: 18px;
            }
            p{
                font-size: 12px;
            }
        }

        .box_right {
            width: 80%;
            margin: 0 auto;
        }
    }
    .el-form-item {
        margin-top: 30px;
        margin-bottom: 35px;
        display: flex;
        flex-wrap: wrap;
        ::v-deep .el-form-item__content{
            margin-left: 0 !important;
        }
        ::v-deep .el-form-item__label{
            text-align: left !important;
        }
    }
}
</style>
<template>
    <div class="home">
        <!-- 本次添加注释仅用于测试提交 -->
        <!-- 顶部组件 -->
        <HeaderPage message="/home"></HeaderPage>
        <!-- 视频展示区域 -->
        <div class="Video_box">
            <!-- <video class="video" muted="muted" src="@/assets/video/test.mp4" autoplay="autoplay" loop>
                您的浏览器不支持视频标签。
            </video> -->
            <img class="video" src="../../assets/image/home_big.png" alt="">
        </div>
        <!-- 游戏展示区域 -->
        <div class="show_game">
            <h1 class="game_tit">ABOUT</h1>
            <span class="game_text">Hey! Look here! We're a cool team dedicated to creating the coolest and most exciting casual gaming experiences. In this digital world, we are not just game developers, but fun leaders and dreamers!
We want to bring cool experiences with simple games. Solitaire is our primary goal, and we hope that our game , Cool Solitaire, will make you feel cool and full of joy.</span>
            <div class="game_btn">
                <router-link class="game_btn_left" to="/game">ALL GAMES</router-link> <i class="game_btn_i el-icon-right"></i>
            </div>
        </div>
        <!-- 游戏滚动展示区 pc -->
        <div class="seamless">
            <vue-seamless-scroll :data="gamesData" class="seamless-warp" :class-option="getRandom(setp, 1)"
                v-for="i in pageNum" :key="i">
                <ul class="item">
                    <li v-for="(item, index) in gamesData" :key="index">
                        <img class="seamless_img" width="212px" :src="item.imgUrl" alt="">
                        <!-- <span class="title" v-text="item.title"></span><span class="date" v-text="item.date"></span> -->
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
        <!-- 游戏滚动展示区 移动 -->
        <div class="seamless2">
            <vue-seamless-scroll :data="gamesData2" :class-option="getRandom3()" class="warp" v-for="i in 3" :key="i">
                <ul class="ul-item">
                    <li class="li-item" v-for="(item, index) in gamesData2" :key="index">
                        <img :src="item.imgUrl" alt="">
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
        <!-- 底部组件 -->
        <FooterPage></FooterPage>
    </div>
</template>
<script>
import HeaderPage from "@/components/header/index"
import FooterPage from "@/components/footer/index"
export default {
    name: 'HomePage',
    components: {
        HeaderPage,
        FooterPage,
    },
    data() {
        return {
            setp: 8,
            pageNum: '',
            gamesData: [
                { imgUrl: require("../../assets/image/seamless" + this.getRandom2(7, 1) + ".jpg"), },
                { imgUrl: require("../../assets/image/seamless" + this.getRandom2(7, 1) + ".jpg"), },
                { imgUrl: require("../../assets/image/seamless" + this.getRandom2(7, 1) + ".jpg"), },
                { imgUrl: require("../../assets/image/seamless" + this.getRandom2(7, 1) + ".jpg"), },
                { imgUrl: require("../../assets/image/seamless" + this.getRandom2(7, 1) + ".jpg"), },

            ],
            gamesData2: [
                { imgUrl: require("../../assets/image/banner_logo1.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo2.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo3.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo4.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo5.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo6.jpg"), },
                { imgUrl: require("../../assets/image/banner_logo7.jpg"), },
            ],
            classOption: {
                limitMoveNum: 2,
                direction: this.getRandom2(1,5),
            },
        }
    },
    mounted() {
        let windowWidth = window.innerWidth;
        this.pageNum = parseInt(windowWidth / 215)>8?8:parseInt(windowWidth / 215);
    },
    methods: {
        getRandom(a, b,flag) {
            if (flag) {
                return { step: (Math.floor(Math.random() * (Math.floor(a) - Math.ceil(b) + 1)) + Math.ceil(b)),direction:'left' }
            }else{
                return { step: (Math.floor(Math.random() * (Math.floor(a) - Math.ceil(b) + 1)) + Math.ceil(b)) }
            }
            
        },
        getRandom2(a, b) {
            return (Math.floor(Math.random() * (Math.floor(a) - Math.ceil(b) + 1)) + Math.ceil(b))
        },
        getRandom3(){
            return {
                limitMoveNum: 2,
                direction: 3,
                step: this.getRandom2(5,1)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;

    .Video_box {
        width: 100%;

        .img {
            width: 100%;
            height: auto;
            // max-height: 4.1667rem;
        }
    }

    .show_game {
        text-align: center;
        width: 3.9063rem;
        margin: 0 auto;
        padding-top: .625rem;

        .game_tit {
            font-size: .2083rem;
            margin-bottom: .1563rem;
        }

        .game_text {
            display: block;
            font-size: .0781rem;
        }

        .game_btn {
            text-align: center;
            margin: .1563rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .0625rem;
            width: .5208rem;
        }

        .game_btn:hover {
            .game_btn_i {
                margin: 0 .026rem;
            }

            .game_btn_left {
                margin: 0 .026rem;
            }
        }

        .game_btn_i {
            color: red;
        }

        .game_btn_left {
            color: red;
        }
    }
    .seamless {
        // border-top: .026rem solid #cfcfcf;
        box-shadow: 0px 0 34px #000;
        /* 设置实际的上边框 */
        display: flex;
        justify-content: space-evenly;

        .seamless-warp {
            width: 1.0938rem;
            height: 6.7708rem;
            overflow: hidden;

            .item {
                .seamless_img {
                    width: 1.0938rem;
                    height: 2.3438rem;
                    border-radius: .1042rem;
                    border: .0521rem solid #e9e9e9;
                }
            }
        }
    }

    .seamless2 {
        display: none;
    }
}

@media (max-width: 1000px) {
    .home {
        width: 100%;

        .Video_box {
            width: 100%;

            .video {
                width: 100%;
                max-height: 210px;
            }
        }

        .show_game {
            text-align: center;
            width: 90%;
            margin: 0 auto;
            padding-top: 80px;
            padding-bottom: 60px;

            .game_tit {
                font-size: 40px;
                margin-bottom: 30px;
            }

            .game_text {
                display: block;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
            }

            .game_btn {
                text-align: center;
                margin: 30px auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                width: 100px;
            }

            .game_btn:hover {
                .game_btn_i {
                    margin: 0 5px;
                }

                .game_btn_left {
                    margin: 0 5px;
                }
            }

            .game_btn_i {
                color: red;
            }

            .game_btn_left {
                color: red;
            }
        }

        .seamless {
            display: none;
        }

        .seamless2 {
            display: block;
            .warp {
                width: 25% * 4;
                height: 120px;
                margin: 0 auto;
                overflow: hidden;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 auto;
                    
                    &.ul-item {
                        display: flex;

                        .li-item {
                            width: 94px;
                            height: 94px;
                            margin-right: 10px;
                            line-height: 80px;
                            background-color: #999;
                            color: #fff;
                            text-align: center;
                            font-size: 30px;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                width: 82px;
                                height: 82px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
    <div class="footer">
        <div class="footer_top">
            <div class="footer_logo">
                <img src="../../assets/image/Logo.png" alt="">
            </div>
            <div class="footer_top_right">
                <h3 class="footer_top_name">Nasi Games</h3>
                <div class="footer_top_menu">
                    <ul>
                        <li><router-link style="text-decoration: none; color: #000;" to="/game">Games</router-link></li>
                        <li><router-link style="text-decoration: none; color: #000;" to="/contactus">CONTACT US</router-link></li>
                    </ul>
                    <ul>
                        <li><router-link style="text-decoration: none; color: #000;" to="/dataprivacy.html">Privacy Policy</router-link></li>
                        <li><router-link style="text-decoration: none; color: #000;" to="/conditions.html">Terms of service</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="footer_bottom_left">
                <!-- <span>COPYRIGHT © 2015-2024 IG SOFT™ ALL RIGHTS RESERVED</span> -->
                <div class="email">nasreenkousarmaa@gmail.com		</div>
            </div>
            <div class="footer_bottom_right">
                <i class="el-icon-setting"></i>English
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterPage'
}
</script>

<style lang="scss" scoped>
.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    .footer_top {
        width: 6.5104rem;
        margin: 0 auto;
        padding: 100px 0 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c4c4c4;
        .footer_logo {
            img {
                width: .6667rem;
                height: auto;
                margin-bottom: 50px;
            }
        }
        .footer_top_right{
            width: 2.0833rem;
            .footer_top_name {
                font-size: 30px;
            }

            .footer_top_menu{
                display: flex;
                justify-content: space-between;
                ul{
                    margin-top: .1563rem;
                    li{
                        list-style: none;
                        line-height: .1563rem;
                    }
                }
            }
        }
        
    }
    .footer_bottom{
        width: 6.5104rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .footer_bottom_left{
            margin-top: 50px;
            span{
                display: block;
                font-size: 12px;
            }
            .email{
                padding: 30px 0;
                font-size: 14px;
            }
        }
        .footer_bottom_right{
            padding-top: 50px;
        }
    }
}

@media (max-width: 750px) {
    .footer {
        .footer_top {
            display: block;
            width: 100%;
            padding-left: 20px;
            .footer_logo {
                img {
                    width: 128px;
                    height: auto;
                }
            }
            .footer_top_name {
                width: 300px;
                font-size: 20px;
            }
            .footer_top_right{
                .footer_top_name {
                    font-size: 30px;
                }
                .footer_top_menu{
                    display: flex;
                    justify-content: space-between;
                    ul{
                        margin-top: 30px;
                        li{
                            width: 200px;
                            list-style: none;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .footer_bottom{
            width: 100%;
            margin: 0 auto;
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
            .footer_bottom_right{
                padding-top: 100px;
            }
        }
    }
}
</style>
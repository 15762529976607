<template>
  <div class="header">
    <a class="header_left" href=""><img src="@/assets/image/Logo.png" alt=""></a>
    <div class="header_right">
      <el-menu :default-active="message" class="el-menu-demo menu_top" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/home"><router-link to="/home"
            style="text-decoration: none;">HOME</router-link></el-menu-item>
        <el-menu-item index="/game"><router-link to="/game"
            style="text-decoration: none;">GAME</router-link></el-menu-item>
        <el-menu-item index="/contactus"><router-link to="/contactus" style="text-decoration: none;">CONTACT
            US</router-link></el-menu-item>
      </el-menu>
      <el-button @click="drawer = true" type="primary" style="margin:0 16px;" icon="el-icon-menu"></el-button>
    </div>
    <el-drawer class="right_drawer" title="Menu" :visible.sync="drawer" :with-header="true">
      <el-menu :default-active="message" class="el-menu-demo menu_right" @select="handleSelect">
        <el-menu-item index="/home">
          <router-link to="/home" style="text-decoration: none;">HOME</router-link>
        </el-menu-item>
        <el-menu-item index="/game">
          <router-link to="/game" style="text-decoration: none;">GAME</router-link>
        </el-menu-item>
        <el-menu-item index="/contactus">
          <router-link to="/contactus" style="text-decoration: none;">CONTACT US</router-link>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {
    message: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      drawer: false,
    }
  },
  mounted() {
    if (this.$route.name == "game") {
      let headerNode = document.getElementsByClassName("header");
      headerNode[0].classList.add("bg_transparent");
    }
  },
  methods: {
    handleSelect(key) {
      this.$router.push({ 'path': key }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
// game页样式变更
.bg_transparent {
  background-color: #0000002e !important;

  .el-menu {
    background-color: transparent !important;
  }

  .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
    background-color: transparent !important;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .header_left {
    display: block;
    width: .8229rem;
    height: .4688rem;
    padding: .1563rem 0 .1563rem .1563rem;

    img {
      width: .6667rem;
      height: auto;
    }
  }

  .header_right {
    display: flex;
  }
}

@media (max-width: 1000px) {
  .header {
    height: 50px;

    .header_left {
      display: block;
      width: 100%;
      height: auto;
      padding: .1563rem 0 .1563rem .1563rem;

      img {
        width: 120px;
        height: auto;
      }
    }
  }

  .menu_top {
    display: none;
  }
}
</style>
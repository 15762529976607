import Vue from 'vue'
import App from './App.vue'
// 加载 element 组件库
import ElementUI from 'element-ui'

// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css'

//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
// 引入无限滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import './utils/fexilb' // 引入px转rem

// 带略缩图的轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import '../node_modules/swiper/dist/css/swiper.css'

// 使用样式库
import 'animate.css';

// 滚动动画 wow.js
import { WOW } from 'wowjs'
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 150, // default
  mobile: true, // default
  live: false, // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});


//应用插件
Vue.use(VueRouter)

// 全局注册 element 组件库
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

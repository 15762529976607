<template>
    <div class="box">
        <HeaderPage></HeaderPage>
        <!-- <img class="box_logo" src="../../assets/image/logo-200x50.png" alt=""> -->
        <div class="container">
            <div data-v-52e32a9b="" class="ng-row">
                <div data-v-52e32a9b="" class="company-desc">
                    <p style="width: 350px; margin: 0 auto 30px; font-size: 30px;">Privacy Policy</p>
                    <p data-v-52e32a9b="" class="desc">
                        The games and other activities offered on the Website and Apps are referred to in this
                        Privacy Policy as the "Services".
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Please read this Policy carefully before accessing the Website, the Apps or using our
                        Services as it provides important information about how we collect, store, use and
                        disclose your personal and other information when providing our Services to you or when
                        you access the Website or Apps. You should also review the terms of use for important
                        information about your use of the Website or Apps and our Services. NG Website and Apps
                        are managed and operated by Impressive Innovation software services ("NG" or "our" or
                        "we" or "us"). Visitors to the NG Website or Apps and users of the Services are referred
                        to as "you."
                    </p>
                    <p data-v-52e32a9b="" class="title">CATEGORIES OF PERSONAL INFORMATION WE COLLECT</p>
                    <p data-v-52e32a9b="" class="subtitle">Passive Information</p>
                    <p data-v-52e32a9b="" class="desc">
                        Passive information refers to information that is automatically collected from your
                        device(s)
                        when you access the Website or Apps and use the Services.
                        NG may collect passive information such as your device ID, IP address, referral data,
                        browser type, browser language, platform type, type of mobile or other computing device,
                        and geographical data, such as zip code and geographic location. Your browser or device
                        typically sends passive information to our servers without your intervention whenever
                        you visit our Website or Apps and use the Services. For example, your browser or device
                        may tell us your IP address (which may tell us generally where you are located) and the
                        type of browser and device you are using. When you visit our Website or Apps, your
                        browser may also tell us information such as the page or link that led you to our
                        Website or Apps and, if applicable, the search terms you typed into a search engine that
                        led you to our Website or Apps.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Cookies</p>
                    <p data-v-52e32a9b="" class="desc">
                        While you are using the Services, we may seek to place a "cookie" (a small data file) or
                        other tracking technology on your mobile device, computer or other participating device.
                        If you access our Website, Apps or Services via a Partner Site, the Partner Site may
                        also place cookies on your device as permitted by the terms of the agreement and your
                        privacy settings with the Partner Site. Cookies placed by us are not used to obtain any
                        personal data, and the information that is tracked is used only for internal purposes,
                        such as to improve the Services. If you prefer not to accept cookies, you can set your
                        web browser on your computer or device to reject them or to alert you before one is
                        placed. Be advised, however, that some of our features and services may not function
                        properly if you disable cookies.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Personal Information</p>
                    <p data-v-52e32a9b="" class="desc">
                        Personal information, which is sometimes referred to as personally identifiable
                        information,
                        means information that is specific to you and can be used to contact or identify you.
                        Unless you are using the Services on a guest basis, you must have a valid, active
                        Facebook account to use and access the Services.
                        Accordingly, we receive from Facebook certain categories of personal information about
                        you that you authorize Facebook to disclose,
                        such as your user name, date of birth, friend list, email address and profile picture,
                        or you may voluntarily disclose information such as your gender or your play preferences
                        to us through your use of the Services. Any information directly collected through your
                        Facebook account and shared with us will be imported, used and stored in a manner
                        consistent with the Facebook Platform Policy applicable to developers in effect at the
                        time of collection (see <a data-v-52e32a9b="" href="https://developers.facebook.com/policy/"
                            target="_blank"><u data-v-52e32a9b="">https://developers.facebook.com/policy/</u></a>)
                        and in compliance with any specific privacy settings set by you of which we are
                        aware.<br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        We may also collect certain categories of personal information from vendors that help us
                        to operate the Website and Apps and provide services such as processing credit card
                        payments and sending emails on our behalf. For example, we may receive confirmation that
                        you have made a payment by credit card and other information related to any of your
                        gaming transactions associated with your use of the Services. To help keep our databases
                        current and to provide you the most relevant content and experiences, we may combine
                        personal information from you with information from public sources and our trusted
                        partners, in accordance with applicable law and this Privacy Policy.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Please note that because a third-party vendor manages your purchases of virtual currency
                        for use the Services, we do not have access to your credit card or billing information.
                        Be advised that this Privacy Policy does not apply to this and other third-party
                        vendors.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        We use Google Analytics to help us manage and improve the Websites and Services. For
                        more information on how Google Analytics collects and processes data, see
                        www.google.com/policies/privacy/partners/. Google provides a Browser Add-On that allows
                        you to opt-out of Google Analytics by downloading and installing the add-on for your web
                        browser, available at: <a data-v-52e32a9b="" href="https://tools.google.com/dlpage/gaoptout"
                            target="_blank"><u data-v-52e32a9b="">https://tools.google.com/dlpage/gaoptout</u></a>.
                    </p>
                    <p data-v-52e32a9b="" class="title">ONLINE ADVERTISING</p>
                    <p data-v-52e32a9b="" class="subtitle">NG Online Advertising</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG utilizes online advertising, including displaying NG advertisements to you across the
                        Internet on websites and in applications. We collect information about which of our
                        advertisements are displayed, which advertisements are clicked on, and on which web page
                        the advertisement was displayed.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Third Party Advertising</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG permits third parties to place advertisements on our properties, including our
                        Website, Apps and the Services. When advertisers or advertising networks place
                        advertisements on NG properties, they may collect or we may share the following types of
                        information from within our properties:
                        <br data-v-52e32a9b="">
                        • performance data (like the number of clicks on an advertisement);
                        <br data-v-52e32a9b="">
                        • aggregated and/or de-identified information about you and other players collectively
                        that is not intended to specifically identify you (like players between the ages of 30
                        and 40 who also own a Lamborghini);
                        <br data-v-52e32a9b="">
                        • certain technical information (for example, IP addresses, non-permanent and anonymous
                        device identifiers such as Apple Identifier For Advertisers (IDFAs), and Google
                        Advertising ID);
                        <br data-v-52e32a9b="">
                        • your social network ID;
                        <br data-v-52e32a9b="">
                        • other contextual data about your game play (for example, games played and session
                        length).
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        After clicking on a third-party advertisement, you may no longer be on a website or
                        other property hosted by NG or the social network through which you are playing our
                        games. Be advised that any personal information that you disclose in connection with a
                        third-party online advertisement is not governed by this Privacy Policy. See below for
                        information on how to opt-out of being served online advertising by third parties.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Offer Walls</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG games or their purchase pages may display an "Offer Wall" that is hosted by a
                        third-party provider. The offer wall allows third party advertisers to provide virtual
                        currency or other rewards to players in exchange for interacting with an advertisement
                        or for completing a marketing offer that may include signing up for an account with one
                        of those advertisers. These offers are not made by NG. These offers may be shown to you
                        based on certain technical information, like your geographic area or de-identified
                        demographic information. After clicking on one of these offers, you will no longer be on
                        a site hosted by NG or the social network through which you are playing NG's games. To
                        properly credit player accounts and to prevent fraud, a unique identifier, in some cases
                        your social network ID or NG player ID, will be shared with the Offer Wall provider.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                    </p>
                    <p data-v-52e32a9b="" class="title">HOW YOUR INFORMATION IS USED</p>
                    <p data-v-52e32a9b="" class="subtitle">Use of Personal Information</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG will use personal information in the following ways:
                        <br data-v-52e32a9b="">
                        • to fulfill your requests for our Services;
                        <br data-v-52e32a9b="">
                        • to process and respond to your inquiries;
                        <br data-v-52e32a9b="">
                        • to improve the Services, including diagnosis or problems with the Services;
                        <br data-v-52e32a9b="">
                        • to help us better understand demographic characteristics of our users;
                        <br data-v-52e32a9b="">
                        • to present advertising that is tailored to your interests;
                        <br data-v-52e32a9b="">
                        • to offer you other opportunities that we think may be of interest to you.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Email and Text Messaging
                        Marketing</p>
                    <p data-v-52e32a9b="" class="desc">
                        In addition, NG may use personal information to communicate with you through email or
                        text messages regarding new products, new features, product updates, enhancements,
                        special offers, or promotions for the application services. These communications may
                        also include offers from NG marketing partners, although such partners will not be
                        provided with your personal information. Emails and text messages we send you may
                        include a web beacon that tells us whether and at what time you have received or opened
                        the email or clicked a link within the email. If you do not want us to use your personal
                        information in this way, or to provide your personal information to third parties for
                        their marketing purposes, please uncheck the appropriate box when you register or you
                        can opt out by contacting us at <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com		"
                            target="_blank"><u data-v-52e32a9b="">nasreenkousarmaa@gmail.com</u></a>. You can also opt out
                        by clicking on the “unsubscribe” button on the emails or texts from us or turning off
                        the NG application notifications in the Services on your smartphone for texts. You can
                        also unsubscribe to marketing messages at any time by clicking “unsubscribe” on the
                        emails or texts that you receive from us or third parties.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Use of Passive Information</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG collects passive information for purposes of tracking and analyzing user preferences
                        and trends in order to improve the quality and design of the Services and to create new
                        features, promotions, and functionalities; for editorial and feedback purposes; for
                        marketing and promotional purposes; for content improvement; and to customize the
                        Services. This information may also be shared with advertisers and our business partners
                        on an aggregated anonymous basis.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Use of Information Collected
                        by Third Party Advertisers</p>
                    <p data-v-52e32a9b="" class="desc">
                        The information collected may be used to:
                        <br data-v-52e32a9b="">
                        • measure how effective advertisements are, to offer you targeted advertising in order
                        to personalize your experience by showing you advertisements for products and services
                        that are more likely to appeal to you (a practice known as behavioral advertising);
                        <br data-v-52e32a9b="">
                        • undertake web analytics to analyze traffic and other player activity to improve your
                        experience; and/or
                        <br data-v-52e32a9b="">
                        • engage in activities such as frequency capping, attribution, conversion events,
                        estimating the number of unique users, advertising fraud detection, and debugging.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Advertisers or advertising networks may collect the foregoing information through the
                        use of tracking technologies like tracking pixels, browser cookies, and web beacons, and
                        they may use a single tracking technology or multiple tracking technologies at the same
                        time.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        If you are interested in more information about online behavioral advertising and your
                        choices to prevent third parties from delivering online behavioral web and mobile web
                        advertising, you may visit the following websites:
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">United States:</p>
                    <p data-v-52e32a9b="" class="desc">
                        • <a data-v-52e32a9b="" href="http://optout.networkadvertising.org/#!/" target="_blank"><u
                                data-v-52e32a9b="">http://optout.networkadvertising.org/#!/</u></a> or
                        <br data-v-52e32a9b="">
                        • <a data-v-52e32a9b="" href="http://optout.aboutads.info/?c=2#!/" target="_blank"><u
                                data-v-52e32a9b="">http://optout.aboutads.info/?c=2#!/</u></a>
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">European Union:</p>
                    <p data-v-52e32a9b="" class="desc">
                        • <a data-v-52e32a9b="" href="http://www.youronlinechoices.com/" target="_blank"><u
                                data-v-52e32a9b="">http://www.youronlinechoices.com/</u></a>.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of
                        the cookies enables it to serve ads to your users based on their visit to our Websites
                        and other websites on the Internet. Users may control their ad settings with Google at:
                        <a data-v-52e32a9b="" href="https://adssettings.google.com/authenticated?hl=en"
                            target="_blank"><u
                                data-v-52e32a9b="">https://adssettings.google.com/authenticated?hl=en</u></a>.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Be advised that these opt-out tools are provided by third parties, not NG, and may not
                        be available with respect to all online advertising that is presented to you. NG does
                        not control or operate these tools or the choices that advertisers and others provide
                        through these tools.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        In addition, if you do not want to receive tailored in-application advertisements from
                        third parties that relate to your interests in apps on your mobile device, you may
                        opt-out by adjusting the ad tracking settings on your device. You can also reset the
                        "Advertising Identifier" (like an IDFA) from your mobile device’s settings page, which
                        will prevent continued use of existing behavioral data tied to the previous "Advertising
                        Identifier."
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                    </p>
                    <p data-v-52e32a9b="" class="subtitle">INFORMATION SHARING AND DISCLOSURE</p>
                    <p data-v-52e32a9b="" class="desc">
                        NG works with third-party vendors that help us provide the Services and operate the
                        Website and Apps. These companies provide services such as processing credit card
                        payments and sending emails on our behalf. In some cases, these companies have access to
                        those portions of your personal information that are necessary in order to provide a
                        product or service you have requested or in conjunction with your use of the Services.
                        Unless we inform you otherwise, these companies are not permitted to use your personal
                        information for any other purpose.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        NG will not disclose your personal information to third parties except in the following
                        circumstances: (1) We may disclose personal information in response to legal process;
                        for example, in response to a court order or a subpoena; (2) We may disclose personal
                        information in response to a law enforcement agency's request, or where we believe it is
                        necessary to investigate, prevent, or take action regarding illegal activities,
                        suspected fraud, situations involving potential threats to the physical safety of any
                        person, violations of our Terms of Use, or as otherwise required by law; (3) We may
                        disclose personal information if we are acquired by or merged with another company or in
                        the unlikely event that NG is dissolved; (4) If we need to disclose your personal
                        information to provide the product or service you have requested;; (5) We may disclose
                        personal information to third parties for analytics purposes. Such personal information
                        may not be used by the third party for any purpose other than this analysis; or (6) We
                        may disclose personal information to our corporate affiliates.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                    </p>
                    <p data-v-52e32a9b="" class="title">HOW YOU CAN MANAGE YOUR INFORMATION</p>
                    <p data-v-52e32a9b="" class="subtitle">Access to and Ability to Correct Personal Information
                    </p>
                    <p data-v-52e32a9b="" class="desc">
                        If you have questions or requests about this Privacy Policy, you may contact us by
                        e-mail at <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com		" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com		</u></a>. Upon request via our online help
                        center, or e-mail, we will provide you a summary of your personal information, if any,
                        retained by us. We will respond to requests to correct, amend, delete, update or delete
                        personal information that is inaccurate within a reasonable timeframe. You can help us
                        maintain the accuracy of your personal information by notifying us of any changes to
                        your information. We will only send personal records to the e-mail address on file for
                        the username associated with it.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        Users may also modify, correct, change, or update their personal record through their
                        Facebook account or request that their personal information be removed from our
                        database.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Access Through Third-Party
                        Sites</p>
                    <p data-v-52e32a9b="" class="desc">
                        The privacy controls for your personal information that is transmitted to us from
                        Facebook are managed through your Facebook account. You should carefully consider and
                        establish your own privacy preferences for the Services through your Facebook account.
                        We are not responsible for personal information, your profile, your location information
                        or other information that you choose to disclose on the Services through synchronization
                        with your Facebook account. Any personal data or other information obtained from your
                        Facebook account will be shared publicly in the Services in accordance with the privacy
                        settings you have applied to such data through your Facebook account.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        If you access the Services through a third-party website ("Partner Site"), you should
                        review the Privacy Notice and Terms of Use for the Partner Site. We do not have
                        responsibility for any information you provide on the Partner Site except the
                        information received from our Partner Sites in a manner consistent with this Privacy
                        Policy and as permitted by the terms of your agreement and privacy setting with the
                        Partner Site.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Opt Out</p>
                    <p data-v-52e32a9b="" class="desc">
                        If you wish to change how the personal information that you provide to Facebook is used
                        by us, including opting out of using your personal information to send promotional
                        messages to you, you may change your settings on your Facebook account or contact us at
                        <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com		" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com		</u></a>.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        In the event you want to opt-out of NG marketing emails or texts, you may do so at any
                        time by clicking on the unsubscribe links located within each NG email or turning off
                        the NG application notifications on your smartphone for texts. You can also contact as
                        at <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com		" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com		</u></a> to opt out. Please note that opting
                        out may affect your ability to receive information from NG or to have access to all of
                        the Services.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        You may also request that your NG account be disabled by emailing <a data-v-52e32a9b=""
                            href="mailto:nasreenkousarmaa@gmail.com		" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com		</u></a>.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                        You may "opt out" of the use of your personal information for third party analytics
                        purposes by emailing (using the same email address as when you registered) your request
                        to <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com		" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com		</u></a><br data-v-52e32a9b=""><br
                            data-v-52e32a9b="">
                        Except as described herein, we will not trade, lease, exchange or sell your personal
                        information with or to any other third party.
                        <br data-v-52e32a9b=""><br data-v-52e32a9b="">
                    </p>
                    <p data-v-52e32a9b="" class="subtitle">International Transfer of Information</p>
                    
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Access to and Ability to
                        Correct Personal Information</p>
                    
                    <p data-v-52e32a9b="" class="title">MISCELLANEOUS</p>
                    <p data-v-52e32a9b="" class="subtitle">Security</p>
                    <p data-v-52e32a9b="" class="desc">
                        We will only collect and use personal information in accordance with this Privacy Policy
                        and to the extent deemed reasonably necessary to serve our legitimate business purposes.
                        We will maintain appropriate administrative, technical, and physical security safeguards
                        to ensure the security, integrity, accuracy and privacy of the information you have
                        provided. Please be aware, however, that no information system is totally secure, and
                        therefore we cannot guarantee against all potential security breaches. While we will use
                        reasonable security and monitoring controls in accordance with general industry
                        standards to secure information you provide to us consistent with our legal
                        requirements, we cannot guarantee that such information will not be unlawfully or
                        illegitimately obtained by unauthorized parties.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Third Party Web Sites</p>
                    <p data-v-52e32a9b="" class="desc">
                        The Services may contain links to other websites, including those of advertisers. NG is
                        not responsible for the security measures, privacy practices, or content of third-party
                        web sites. Once you leave our Website or Apps using any of these links, although no
                        warning will be displayed, the privacy practices mentioned in this Privacy Policy do not
                        apply. You are advised to examine the privacy policies of these third- party sites
                        before providing any personal information.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Age Restrictions</p>
                    <p data-v-52e32a9b="" class="desc">
                        Our Services do not knowingly solicit information from, or market to, any person under
                        the age of twenty-one (21). In particular, consistent with the Children's Online Privacy
                        Protection Act, we will not knowingly collect any information from children under the
                        age of thirteen (13). If you become aware that a member of your family or any person
                        under your responsibility has provided us with information without your consent, please
                        contact us using the contact information as indicated in this Privacy Policy in order to
                        delete that information as quickly as possible.
                    </p>
                    <p data-v-52e32a9b="" class="subtitle"><br data-v-52e32a9b="">Changes to the Privacy Notice
                    </p>
                    <p data-v-52e32a9b="" class="desc">
                        We may revise this Privacy Policy at any time as we may deem appropriate and consistent
                        with the principles described herein, our evolving business needs, or changes in
                        applicable laws or regulations. If we make any changes to this Privacy Policy, we will
                        update the "Last updated" date below. You are advised to consult this Privacy Policy
                        regularly for any changes, and your continued use of this Website, the Apps and our
                        Services after such changes have been made constitutes informed consent of those
                        changes.
                    </p>
                    <p data-v-52e32a9b="" class="title">HOW TO CONTACT US</p>
                    <p data-v-52e32a9b="" class="desc">
                        If you have any concern about this Privacy Policy or our privacy practices, please
                        contact us at:
                        <br data-v-52e32a9b="">
                        Interactive Impressive Innovation software services
                        <br data-v-52e32a9b="">
                        Or email us at <a data-v-52e32a9b="" href="mailto:nasreenkousarmaa@gmail.com" target="_blank"><u
                                data-v-52e32a9b="">nasreenkousarmaa@gmail.com</u></a>
                    </p>
                </div>
            </div>
        </div>
        <!-- 底部组件 -->
        <FooterPage></FooterPage>
    </div>
</template>

<script>
import FooterPage from "../../components/footer/index.vue"
import HeaderPage from "../../components/header/index.vue"

export default {
    name: 'DataPrivacy',
    components: {
        FooterPage,
        HeaderPage
    }
}
</script>

<style lang="scss" scoped>

.ng-row{
    .company-desc{
        .title{
            font-weight: bolder;
            font-size: 24px;
            line-height: 30px;
            margin: 30px 0 0;
        }
        .subtitle{
            font-weight: bolder;
            font-size: 18px;
            line-height: 30px;
        }
        .desc{
            font-size: 16px;
            line-height: 30px;
        }
    }
}









.box {
    background: #fff;
    width: 100%;
    padding-bottom: .4167rem;

    h1 {
        text-align: center;
        margin: .4167rem;
        color: #2fccbf;
    }
}

.container {
    width: 6.7708rem;
    margin: 0 auto;

    .notice {
        font-size: 14px;
        line-height: 30px;
        width: 100%;
        display: block;
        word-wrap: break-word;
        margin: 0 auto .2604rem;
    }

    span {
        font-size: 14px;
        line-height: .1563rem;
    }

    .tit {
        display: block;
        margin: .1042rem 0;
    }
}

.ul_1 {
    margin-top: .2604rem;
    list-style-type: decimal;
    margin-left: -1em;
    line-height: .1823rem;
    font-size: 20px;

    .ul_2 {
        font-size: 14px;
        list-style-type: lower-alpha;
        text-align: left;
        line-height: .1302rem;
        padding-left: .026rem;
        margin: .0521rem 0;

        .ul_3 {
            font-size: 14px;
            list-style-type: disc;
            text-align: left;
            line-height: .1302rem;
            padding-left: .0521rem;
            margin: .0521rem 0;
        }
    }
}

.box_logo {
    width: 200px;
    height: auto;
    position: relative;
    left: 2.0313rem;
    top: .5729rem;
}

@media (max-width: 1000px) {
    .box_logo {
        width: 80px;
        height: auto;
        position: relative;
        left: .1042rem;
        top: 30px;
    }

    .container {
        h1 {
            font-size: 24px;
        }

        .tit {
            font-size: 0.01em;
            line-height: 30px;
        }
    }

    .ul_1 {
        font-size: 10px;

        strong {
            line-height: 20px;
        }

        p {
            margin: 50px 0 0;
        }

        .ul_2 {
            line-height: 30px;
            padding-left: .0521rem;

            .ul_3 {
                line-height: 30px;
                padding-left: .0521rem;
            }
        }
    }
}
</style>
<template>
    <div>
        <HeaderPage message="/game"></HeaderPage>

        <!-- 顶部轮播图--带有略缩图 -->
        <div class="thumb-example">
            <!-- swiper1 -->
            <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide class="slide-1" v-for="item in bigImg" :key="item.id">
                    <img :src="item.url" style="height:auto;width:100%" alt="" />
                </swiper-slide>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            </swiper>
            <!-- swiper2 Thumbs -->
            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide class="slide" v-for="item in bigImg" :key="item.id">
                    <img style="width:100%;height:auto; border-radius: 25px;" :src="item.imgUrl" alt="" />
                </swiper-slide>
            </swiper>
        </div>

        <!-- 选项卡 -->
        <div class="tabs_box" id="tabs_box">
            <div class="tabs_box_container">
                <el-tabs :tab-position="tabPosition" >
                    <el-tab-pane class="game_home" label="Games Home">
                        <RotationDisplay2 title="Hot Game" :bannerData="RotationDisplay2_1"></RotationDisplay2>

                        <div class="higt_game">
                            <h1 class="higt_game_title" style="font-size: .1563rem;"></h1>
                            <GameItem :gameData="gameData1"></GameItem>
                        </div>
                        <RotationDisplay2 title="Popular Game" :bannerData="RotationDisplay2_2"></RotationDisplay2>
                    </el-tab-pane>
                    <el-tab-pane label="Timeline">
                        <div class="timeline">
                            <div class="timeline_top" style="text-align: center; padding-top: .3385rem"> </div>
                            <div class="comingSoon">
                                <h1 style="font-size: .2813rem; line-height: .2813rem;">Coming Soon</h1>
                                <p style="font-size: .0729rem; margin: 30px;">SPLASHING WATER FESTIVAL</p>
                            </div>
                            <!-- 使用时间线组件 -->
                            <TimeLine :timelineList="timelineList"></TimeLine>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="All Games">
                        <h1 style="margin-bottom: 50px; font-size: .1563rem;">ALL GAMES</h1>
                        <GameItem :gameData="gameData2"></GameItem>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <FooterPage></FooterPage>
        </div>

    </div>
</template>

<script>
import HeaderPage from "@/components/header/index"
import RotationDisplay2 from "@/components/RotationDisplay2/index"
import TimeLine from "@/components/timeline/index"
import GameItem from "@/components/gameitem/index"
import FooterPage from "@/components/footer/index"
export default {
    name: "GamePage",
    components: {
        HeaderPage,
        RotationDisplay2,
        TimeLine,
        GameItem,
        FooterPage
    },
    data() {
        return {
            tabPosition:'left',
            //轮播大图配置 // 已更新
            bigImg: [{
                url: require("../../assets/image/banner1.jpg"),
                imgUrl: require("../../assets/image/banner_logo1.jpg"),
                id: 1,
            }, {
                url: require("../../assets/image/banner4.jpg"),
                imgUrl: require("../../assets/image/banner_logo4.jpg"),
                id: 2,
            }, {
                url: require("../../assets/image/banner2.jpg"),
                imgUrl: require("../../assets/image/banner_logo2.jpg"),
                id: 3,
            }],
            swiperOptionTop: {
                zoom: true,
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                autoplay: {  //自动轮播
                    delay: 4000,
                    disableOnInteraction: false
                },

            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true,

            },
            // 文案已更新
            RotationDisplay2_1: [
                {
                    flag: true,
                    imgUrl: require("@/assets/image/banner1.jpg"),
                    minimgUrl: require("../../assets/image/higt_game1.jpg"),
                    iconUrl: require("@/assets/image/banner_logo1.jpg"),
                    title: 'Cool Solitaire',volatility: "medium", rtp: "96.55%", maximun: "x340",
                    text: "Cool Solitaire - the ultimate fun experience in the world of solitaire! Here you will find pure, innocent gaming fun without the headache of in-play purchases, and all the fun you want without paying for it!You can share the classic card game experience with players all over the world. Whether you're a seasoned solitaire player or a newbie just starting to explore the world of solitaire, this game will bring you endless fun and challenges.Isn't it cool that we have purposely designed the simplest and smoothest interface so that you can focus on the game itself?Come and challenge your skills and intelligence and find challenges in different difficulty levels. We've adopted a more challenging triple-catch mode to give you an experience of both difficulty and fun!Come on! Let's experience the fun of card games together and be the coolest player!",
                    imgUrl2: require("@/assets/image/banner4.jpg"),
                    minimgUrl2: require("../../assets/image/higt_game4.jpg"),
                    iconUrl2: require("@/assets/image/banner_logo4.jpg"),
                    title2: 'Shadow Pact',
                    volatility2: "low", rtp2: "96.41%", maximun2: "x5000",
                    text2: "In this role-playing game, players take on the role of a chosen hero by a mysterious organization, tasked with uncovering the secrets of the shadow pact and protecting the world from the invasion of dark forces.",
                },
                {
                    flag: true,
                    imgUrl: require("@/assets/image/banner2.jpg"),
                    minimgUrl: require("../../assets/image/higt_game2.jpg"),
                    iconUrl: require("@/assets/image/banner_logo2.jpg"),
                    title: 'Journey Through Illusions: Lost Stars',volatility: "higt", rtp: "96.80%", maximun: "X15000",
                    text: "In this adventure game, players will traverse mysterious illusions, explore the lost stars, unravel ancient mysteries, interact with fantastical creatures, and ultimately reveal the forces behind the scenes.",
                },
            ],
            RotationDisplay2_2: [
                {
                    flag: false,
                    imgUrl: require("@/assets/image/banner1.jpg"),
                    minimgUrl: require("../../assets/image/higt_game1.jpg"),
                    iconUrl: require("@/assets/image/banner_logo1.jpg"),
                    title: 'Cool Solitaire',volatility: "medium", rtp: "96.55%", maximun: "x340",
                    text: "Cool Solitaire - the ultimate fun experience in the world of solitaire! Here you will find pure, innocent gaming fun without the headache of in-play purchases, and all the fun you want without paying for it!You can share the classic card game experience with players all over the world. Whether you're a seasoned solitaire player or a newbie just starting to explore the world of solitaire, this game will bring you endless fun and challenges.Isn't it cool that we have purposely designed the simplest and smoothest interface so that you can focus on the game itself?Come and challenge your skills and intelligence and find challenges in different difficulty levels. We've adopted a more challenging triple-catch mode to give you an experience of both difficulty and fun!Come on! Let's experience the fun of card games together and be the coolest player!",
                    imgUrl2: require("@/assets/image/banner4.jpg"),
                    minimgUrl2: require("../../assets/image/higt_game4.jpg"),
                    iconUrl2: require("@/assets/image/banner_logo4.jpg"),
                    title2: 'Shadow Pact',volatility2: "low", rtp2: "96.41%", maximun2: "x5000",
                    text2: "In this role-playing game, players take on the role of a chosen hero by a mysterious organization, tasked with uncovering the secrets of the shadow pact and protecting the world from the invasion of dark forces.",
                },
                {
                    flag: false,
                    imgUrl: require("@/assets/image/banner2.jpg"),
                    minimgUrl: require("../../assets/image/higt_game2.jpg"),
                    iconUrl: require("@/assets/image/banner_logo2.jpg"),
                    title: 'Journey Through Illusions: Lost Stars',volatility: "higt", rtp: "96.80%", maximun: "X15000",
                    text: "In this adventure game, players will traverse mysterious illusions, explore the lost stars, unravel ancient mysteries, interact with fantastical creatures, and ultimately reveal the forces behind the scenes.",
                },
            ],
            // 时间线组件数据// 已更新
            timelineList: [
                {
                    date: "2024-0412", title: "Cool Solitaire",
                    content: "Cool Solitaire - the ultimate fun experience in the world of solitaire! Here you will find pure, innocent gaming fun without the headache of in-play purchases, and all the fun you want without paying for it! You can share the classic card game experience with players all over the world. Whether you're a seasoned solitaire player or a newbie just starting to explore the world of solitaire, this game will bring you endless fun and challenges",
                    imgUrl: require("../../assets/image/higt_game1.jpg"),
                }, {
                    date: "2024-0313", title: "Journey Through Illusions: Lost Stars",
                    content: " In this adventure game, players will traverse mysterious illusions, explore the lost stars, unravel ancient mysteries, interact with fantastical creatures, and ultimately reveal the forces behind the scenes.",
                    imgUrl: require("../../assets/image/higt_game2.jpg"),
                }, {
                    date: "2024-0312", title: "City of the Future",
                    content: "Build, manage, and develop your own city of the future. Starting from an empty plot of land, establish a technologically advanced city, tackle various challenges, and achieve city prosperity and resident happiness.",
                    imgUrl: require("../../assets/image/higt_game3.jpg"),
                }, {
                    date: "2024-0311", title: "Shadow Pact",
                    content: "In this role-playing game, players take on the role of a chosen hero by a mysterious organization, tasked with uncovering the secrets of the shadow pact and protecting the world from the invasion of dark forces.",
                    imgUrl: require("../../assets/image/higt_game4.jpg"),
                }, {
                    date: "2024-0310", title: "Interstellar Explorer",
                    content: "Become an explorer, piloting your interstellar spaceship to explore the endless universe. Discover new planets, civilizations, and resources, interact and cooperate with other players or engage in conflicts.",
                    imgUrl: require("../../assets/image/higt_game5.jpg"),
                }, {
                    date: "2024-0309", title: "Mystery of Miracles",
                    content: "This is a puzzle-adventure game where players will unravel various mysteries in a mysterious world, explore forgotten miracles, and uncover the truth hidden deep within history.Legendary Wars: Dawn of Battle.",
                    imgUrl: require("../../assets/image/higt_game6.jpg"),
                },
            ],
            gameData1: [// 已更新
                {
                    title: 'Cool Solitaire',
                    // volatility: "medium", rtp: "96.55%", maximun: "x340",
                    iconUrl: require("../../assets/image/banner_logo1.jpg"),
                    imgUrl: require("../../assets/image/higt_game1.jpg"),
                    text: "Cool Solitaire - the ultimate fun experience in the world of solitaire! Here you will find pure, innocent gaming fun without the headache of in-play purchases, and all the fun you want without paying for it!You can share the classic card game experience with players all over the world. Whether you're a seasoned solitaire player or a newbie just starting to explore the world of solitaire, this game will bring you endless fun and challenges.Isn't it cool that we have purposely designed the simplest and smoothest interface so that you can focus on the game itself?Come and challenge your skills and intelligence and find challenges in different difficulty levels. We've adopted a more challenging triple-catch mode to give you an experience of both difficulty and fun!Come on! Let's experience the fun of card games together and be the coolest player!",
                }, {
                    title: 'Journey Through Illusions: Lost Stars',
                    // volatility: "higt", rtp: "96.80%", maximun: "X15000",
                    iconUrl: require("../../assets/image/banner_logo2.jpg"),
                    imgUrl: require("../../assets/image/higt_game2.jpg"),
                    text: "In this adventure game, players will traverse mysterious illusions, explore the lost stars, unravel ancient mysteries, interact with fantastical creatures, and ultimately reveal the forces behind the scenes.",
                }, {
                    title: "Interstellar Explorer",
                    //  volatility: "higt", rtp: "96.80%", maximun: "X15000",
                    iconUrl: require("../../assets/image/banner_logo3.jpg"),
                    imgUrl: require("../../assets/image/higt_game3.jpg"),
                    text: "Become an explorer, piloting your interstellar spaceship to explore the endless universe. Discover new planets, civilizations, and resources, interact and cooperate with other players or engage in conflicts.",
                },
            ],
            gameData2: [
                {
                    title: 'Cool Solitaire',volatility: "medium", rtp: "96.55%", maximun: "x340",
                    iconUrl: require("../../assets/image/banner_logo1.jpg"),
                    imgUrl: require("../../assets/image/higt_game1.jpg"),
                    text: "Cool Solitaire - the ultimate fun experience in the world of solitaire! Here you will find pure, innocent gaming fun without the headache of in-play purchases, and all the fun you want without paying for it!You can share the classic card game experience with players all over the world. Whether you're a seasoned solitaire player or a newbie just starting to explore the world of solitaire, this game will bring you endless fun and challenges.Isn't it cool that we have purposely designed the simplest and smoothest interface so that you can focus on the game itself?Come and challenge your skills and intelligence and find challenges in different difficulty levels. We've adopted a more challenging triple-catch mode to give you an experience of both difficulty and fun!Come on! Let's experience the fun of card games together and be the coolest player!",
                }, {
                    title: "Journey Through Illusions: Lost Stars", volatility: "low", rtp: "96.41%", maximun: "x5000",
                    iciconUrlonImg: require("../../assets/image/banner_logo2.jpg"),
                    imgUrl: require("../../assets/image/higt_game2.jpg"),
                    text: "In this adventure game, players will traverse mysterious illusions, explore the lost stars, unravel ancient mysteries, interact with fantastical creatures, and ultimately reveal the forces behind the scenes.",
                }, {
                    title: "City of the Future", volatility: "higt", rtp: "96.80%", maximun: "X15000",
                    iconUrl: require("../../assets/image/banner_logo3.jpg"),
                    imgUrl: require("../../assets/image/higt_game3.jpg"),
                    text: "Build, manage, and develop your own city of the future. Starting from an empty plot of land, establish a technologically advanced city, tackle various challenges, and achieve city prosperity and resident happiness.",
                }, {
                    title: "Shadow Pact", volatility: "low", rtp: "96.74%", maximun: "X50000",
                    iconUrl: require("../../assets/image/banner_logo4.jpg"),
                    imgUrl: require("../../assets/image/higt_game4.jpg"),
                    text: "In this role-playing game, players take on the role of a chosen hero by a mysterious organization, tasked with uncovering the secrets of the shadow pact and protecting the world from the invasion of dark forces.",
                }, {
                    title: "Interstellar Explorer", volatility: "medium", rtp: "96.78%", maximun: "X15000",
                    iconUrl: require("../../assets/image/banner_logo5.jpg"),
                    imgUrl: require("../../assets/image/higt_game5.jpg"),
                    text: "Become an explorer, piloting your interstellar spaceship to explore the endless universe. Discover new planets, civilizations, and resources, interact and cooperate with other players or engage in conflicts.",
                }, {
                    title: "Mystery of Miracles", volatility: "higt", rtp: "96.68%", maximun: "x1081",
                    iconUrl: require("../../assets/image/banner_logo6.jpg"),
                    imgUrl: require("../../assets/image/higt_game6.jpg"),
                    text: "This is a puzzle-adventure game where players will unravel various mysteries in a mysterious world, explore forgotten miracles, and uncover the truth hidden deep within history.Legendary Wars: Dawn of Battle",
                }, {
                    title: "Legendary Wars: Dawn of Battle", volatility: "medium", rtp: "96.72%", maximun: "X15000",
                    iconUrl: require("../../assets/image/banner_logo7.jpg"),
                    imgUrl: require("../../assets/image/higt_game7.jpg"),
                    text: "In this strategy game, you'll become a military commander, leading your army to battle on all fronts. Engage in strategic confrontations with other players, vie for resources, and build an epic saga of legendary warfare",
                }
            ],
        }
    },
    mounted() {
        if (window.innerWidth>1000) {
            this.tabPosition = 'left'
        }else{
            this.tabPosition = 'top'
        }
        let _this = this
        // 实现swiper双向控制
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.swiper
            const swiperThumbs = this.$refs.swiperThumbs.swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
        });
        window.addEventListener('scroll', function () {
            setTimeout(() => {
                const curPath = _this.$route.path;
                if (curPath=='/game') {
                    var tabs_box = document.getElementById('tabs_box');
                    var scroll = window.scrollY; // 获取当前滚动位置
                    if(window.innerWidth>1000){
                        tabs_box.style.marginTop = ((670 - scroll * 0.1) < 0 ? 0 : (670 - scroll * 0.1)) + 'px'; // 根据滚动位置动态调整margin-top
                    }else{
                        tabs_box.style.marginTop = ((145 - scroll * 0.1) < 0 ? 0 : (145 - scroll * 0.1)) + 'px'; // 根据滚动位置动态调整margin-top
                    }
                    
                }
            }, 1)

        });
    },
}
</script>

<style lang="scss" scoped>
/** 轮播图样式区域 */
.thumb-example {
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: 0px;
    position: fixed;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.gallery-top {
    width: 100%;
}

.gallery-thumbs {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20% !important;
    box-sizing: border-box;
    padding: .0521rem 0rem;
    width: 2.6042rem;
    margin-left: .0104rem;

    .swiper-button-next {
        right: 0px;
    }

    .swiper-button-prev {
        left: 0px;
    }

    .swiper-button-next:hover div {
        background: rgb(189, 186, 186);
    }

    .swiper-button-prev:hover div {
        background: rgb(189, 186, 186);
    }

    .slide {
        width: .5208rem;
        height: .5208rem;
    }
}

.gallery-thumbs .swiper-slide {
    width: 20%;
    height: .4167rem;
}

/** 当前展示图片的略缩图添加边框 */
.gallery-thumbs .swiper-slide-active {
    width: .5729rem !important;
    height: .5729rem !important;
    border-radius: .1458rem;
    border: .026rem solid rgb(103 178 255);
}

/** 轮播图样式区域结束 */

/** 选项卡区域样式 */
.tabs_box {
    position: relative;
    z-index: 2;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(.1042rem);
    margin-top: 670px;
    height: 840px;
    overflow: scroll;

    .tabs_box_container {
        width: 6.25rem;
        // height: 100%;
        margin: 0 auto;
        padding: .2604rem 0 0;
    }
}

.el-tabs__nav-scroll {
    padding-top: .2604rem !important;
}

#tabs_box {
    transition: margin-top 0.3s ease;
    /* 平滑过渡效果 */
}

.game_home {
    width: 5.5208rem;
}

.higt_game {
    .higt_game_title {
        margin: 0 0 .1563rem;
    }
}

.timeline {
    position: relative;
    .comingSoon{
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
    }
    .timeline_top {
        background-image: url("../../assets/image/banner1.jpg");
        background-size: cover;
        width: 1060px;
        height: 1.4583rem;
        margin-bottom: .2604rem;
        filter: blur(5px);
    }
}

@media (max-width: 1000px) {
    .tabs_box{
        margin-top: 145px;
    }
    .thumb-example {
        margin-top: 0;
    }

    .gallery-thumbs {
        height: auto;

        .slide {
            width: 25px;
            height: 25px;
        }
    }

    .gallery-thumbs .swiper-slide-active {
        width: 25px !important;
        height: 25px !important;
        border-radius: 5px;
        border: 1px solid rgb(103 178 255);
    }

    .tabs_box_container{
        padding: 0;
        margin: 0;
    }
    .el-tabs {
        width: 100%;
    }
    

    
  .el-tabs__content{
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .tabs_box_container{
    width: 90% !important;
    margin: 0 auto;
  }
  .el-tab-pane{
    width: 80%;
    margin: 0 auto;
  }

}
</style>
<template>
  <div class="rotation_display">
    <div class="rotation_display_title">
      <h1>{{ title }}</h1>
    </div>
    <el-button-group class="topbannerbtn" v-show="buttons">
        <el-button size="mini" icon="el-icon-arrow-left" @click="prevSlide"></el-button>
        <el-button size="mini" type="warning" @click="nextSlide"><i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </el-button-group>
    <el-carousel indicator-position="none" :interval="0" ref="myCarousel" arrow="never" style="width: 100%; height: 1.8229rem; margin-bottom: .2604rem;">
      <el-carousel-item v-for="(item, index) in bannerData" :key="index">
        <div style="width: 100%; overflow: hidden;" class="rotation_display_item">
          <div class="rotation_display_left" style="cursor: pointer; height: 100%;">
            <img class="rotation_display_item_img" :src="item.imgUrl" alt="">
            <div class="rotation_display_item_down" v-show="item.flag">
              <img class="rotation_display_item_icon" :src="item.iconUrl">
              <div>
                <h3 style="font-size: .125rem;">{{ item.title }}</h3>
                <p style="font-size: .125rem;">{{ item.text }}</p>
              </div>
            </div>
          </div>
          <div class="rotation_display_right" style="cursor: pointer; height: 100%;">
            <img class="rotation_display_item_img" :src="item.imgUrl2" alt="">
            <div class="rotation_display_item_down"  v-show="item.flag">
              <img class="rotation_display_item_icon" :src="item.iconUrl2">
              <div>
                <h3 style="font-size: .125rem;">{{ item.title2 }}</h3>
                <p style="font-size: .125rem;">{{ item.text2 }}</p>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'RotationDisplay2',
  props: {
    title: {
      type: String,
      required: true
    },
    bannerData: {
      type: Array,
      require: true
    },
  },
  data(){
    return{
      buttons:true,
    }
  },
  mounted(){
    // console.log(this.bannerData[0].iconUrl);
    if (window.innerWidth>1000) {
      this.buttons = true;
    }else{
      this.buttons = false;
    }
  },
  methods:{
    // 进入详情页的方法
    toDetail(flag,item){
      let params = {};
      if (flag) {
        params.title = item.title;
        params.text = item.text;
        params.volatility = item.volatility;
        params.rtp = item.rtp;
        params.maximun = item.maximun;
        params.iconUrl = item.iconUrl;
        params.imgUrl = item.minimgUrl;
      }else{
        params.title = item.title2;
        params.text = item.text2;
        params.volatility = item.volatility2;
        params.rtp = item.rtp2;
        params.maximun = item.maximun2;
        params.iconUrl = item.iconUrl2;
        params.imgUrl = item.minimgUrl2;
      }
    },
    // 翻页方法*2
    prevSlide() {
        this.$refs.myCarousel.prev();
    },
    nextSlide() {
        this.$refs.myCarousel.next();
    },
  },
}
</script>

<style lang="scss" scoped>
.rotation_display {
  width: 100%;
  position: relative;
}

.rotation_display_title {
  margin: 0 0 .1563rem;
  h1{
    font-size: .1875rem;
  }
}
.el-carousel__item{
  height: 320px;
}
::v-deep .el-tabs .el-tabs--top{
  width: 90%;
}
::v-deep .el-tabs__content{
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.rotation_display_item {
  height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rotation_display_left{
  width: 2.5521rem;
}
.rotation_display_item_img {
  width: 2.5521rem;
  border-radius: .0521rem;
  height: auto;
}
.rotation_display_item_down{
  display: flex;
  justify-content: space-evenly;
  padding: .0521rem 0 0;
  .rotation_display_item_icon{
    width: .4688rem;
    height: auto;
  }
  div{
    p{
      width: 1.4479rem;
      overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp:2;
			-webkit-box-orient:vertical;
    }
  }
}
.rotation_display_right{
  width: 2.5521rem;
}
.topbannerbtn{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
@media (max-width: 1000px) {
  .rotation_display_item{
    min-height: 150px;
    max-height: 200px;
    overflow: hidden;
    justify-content: space-evenly;
  }
  .el-tabs .el-tabs--top{
    width: 90% !important;
  }
}
</style>
<template>
    <div class="showGame">
        <div class="showGame_item" v-for="(item, index) in gameData" :key="index">
            <div class="showGame_item_down" :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }">
                <div class="showGame_bg">
                    <div class="showGame_bg_up">
                        <div class="showGame_bg_up_left">
                            <img :src="item.iconUrl" alt="">
                        </div>
                        <div class="showGame_bg_up_right">
                            <p class="p1" style="font-size: .0938rem; color: #fff;">{{ item.title }}</p>
                            <p class="p2" style="font-size: .0625rem; color: #fff;">{{ item.text }}</p>
                        </div>
                    </div>
                    <div class="showGame_bg_center"
                        style="display: flex; justify-content: space-evenly; margin: .1042rem 0;">
                        <!-- <div>
                            <p style="font-size: .0938rem; color: #fff;">{{ item.volatility }}</p>
                            <p style="font-size: .0625rem; color: #fff;">Volatility</p><br>
                            <p style="font-size: .0938rem; color: #fff;">4</p>
                            <p style="font-size: .0625rem; color: #fff;">Languages</p>
                        </div> -->
                        <!-- <div>
                            <p style="font-size: .0938rem; color: #fff;">{{ item.rtp }}</p>
                            <p style="font-size: .0625rem; color: #fff;">RTP</p>
                        </div>
                        <div>
                            <p style="font-size: .0938rem; color: #fff;">{{ item.maximun }}</p>
                            <p style="font-size: .0625rem; color: #fff;">Maximun Win</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GameItem",
    props: {
        gameData: {
            type: Array,
            require: true,
        }
    },
    data() {
        return {
            itemDate:{},
        }
    },
    methods: {
    },
}

</script>

<style lang="scss" scoped>
.showGame {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;

    .showGame_item {
        width: 1.6667rem;
        border-radius: .0781rem;
        margin: 0 .0781rem;

        img {
            width: 100%;
            height: auto;
            border-radius: .0781rem .0781rem 0 0;
        }

        margin-bottom: .1563rem;

        .showGame_item_down {
            background-size: contain;
            width: 100%;
            height: 1.5625rem;
            position: relative;
            border-radius: .0781rem .0781rem;

            .showGame_bg {
                width: 100%;
                height: calc(100% - .9375rem);
                backdrop-filter: blur(.2083rem);
                padding: .1042rem 0;
                position: absolute;
                bottom: -1px;
                border-radius: 0 0 .0781rem .0781rem;

                .showGame_bg_up {
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    padding-left: .1406rem;

                    .showGame_bg_up_left {
                        img {
                            width: .3125rem;
                            height: auto;
                        }
                    }

                    .showGame_bg_up_right {
                        .p2 {
                            width: 1.1458rem;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .showGame{
        justify-content: space-evenly;
    }
    .showGame_item_down{
        height: 70px !important;
    }
}
</style>